import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const navigate = useNavigate();

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setActiveMenu('home');
                break;
            case '/lookup':
                setActiveMenu('lookup');
                break;
            case '/ip-tracker':
                setActiveMenu('ip-tracker');
                break;
            case 'block':
                    setActiveMenu('block');
                break;
            case '/isd-codes':
                setActiveMenu('isd-codes');
                break;
                case '/search':
                    setActiveMenu('search');
                    break;
            case '/history':
                setActiveMenu('history');
                break;
            case '/login':
                setActiveMenu('login');
                break;
            case '/register':
                setActiveMenu('register');
                break;
            default:
                setActiveMenu('');
                break;
        }
    }, [location.pathname]);

    const getNavbarStyle = () => {
        return location.pathname === '/register' || location.pathname === '/login'
            ? { backgroundColor: 'white', color: 'black' }
            : {
                  background: 'linear-gradient(103.59deg, #0A06E0 0%, #0085FF 120.96%)',
                  color: 'white'
              };
    };

    const getLoginButtonStyle = () => ({
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        padding: '10px 20px',
        borderRadius: '2px',
        cursor: 'pointer',
    });

    const getAuthButtonStyle = () => ({
        backgroundColor: '#FF7A00',
        fontSize: '14px',
        color: 'white',
        border: '1px solid white',
        padding: '10px 20px',
        borderRadius: '2px',
        cursor: 'pointer',
    });

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        setIsLoggedIn(location.pathname !== '/login' && location.pathname !== '/register');
    }, [location.pathname]);

    const handleAuthClick = () => {
        if (isLoggedIn) {
            setIsLoggedIn(false);
            navigate('/login');
        } else {
            navigate('/login');
        }
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <nav className="navbar" style={getNavbarStyle()}>
            <div className="navbar-brand" style={{ color: getNavbarStyle().color }}>LOOKUP</div>
            <div className="menu-icon" onClick={toggleMenu}>
                {!isMenuOpen && <FontAwesomeIcon icon={faBars} />}
            </div>
            {isLoggedIn && (
                <ul className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
                    <li><Link to="/" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'home' ? 'active' : ''}>Home</Link></li>
                    <li><Link to="/lookup" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'lookup' ? 'active' : ''}>Number Locator</Link></li>
                    <li><Link to="/ip-tracker" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'ip-tracker' ? 'active' : ''}>IP Tracker</Link></li>
                    <li><Link to="/search" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'search' ? 'active' : ''}>Block</Link></li>
                    {/* <li><Link to="/block" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'block' ? 'active' : ''}>Block</Link></li> */}
                    <li><Link to="/isd-codes" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'isd-codes' ? 'active' : ''}>ISD Codes</Link></li>
                    <li><Link to="/history" style={{ color: getNavbarStyle().color }} onClick={closeMenu} className={activeMenu === 'history' ? 'active' : ''}>History</Link></li>
                </ul>
            )}
            <div className={`auth-links ${isMenuOpen ? 'open' : ''}`}>
                {!isLoggedIn ? (
                    <>
                        <Link to="/login" className="login" style={getLoginButtonStyle()} onClick={closeMenu}>Login</Link>
                        <Link to="/register" className="register" style={getAuthButtonStyle()} onClick={closeMenu}>Register</Link>
                    </>
                ) : (
                    <button className="logout" onClick={handleAuthClick} style={getAuthButtonStyle()}>
                        Logout
                    </button>
                )}
            </div>
        </nav>
    );
};

export default Navbar;

import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ message, onClose }) => (
  <div className="modal-overlay">
    <div className="modal">
      <h3>Error</h3>
      <p>{message}</p>
      <button onClick={onClose} className="close-button">Close</button>
    </div>
  </div>
);

export default ErrorModal;
